import { FileWithPath } from '@mantine/dropzone';
import ky, { HTTPError, Options } from 'ky';
import urlJoin from 'url-join';
import { ApiRoutes } from './ApiRoutes';
import type { IToken } from './Auth';
import { API_URL } from './Config';
import { IUser } from './models/User';
import { getLogger } from './services/Logger';

const log = getLogger('Api');

const DefaultConfig: Options = {
  prefixUrl: urlJoin(API_URL, 'api'),
};

export interface IApiError {
  error: string;
  message: string;
  statusCode: number;
}

export interface ILoginPayload {
  user: IUser;
  token: IToken;
}

const enum ErrorMessageId {
  UserNotFound = 'error.userNotFound',
}

const ErrorMessage: Record<string, string> = {
  [ErrorMessageId.UserNotFound]:
    'The username was not found or the password was incorrect.',
};

class Api {
  public readonly ky: typeof ky;

  public readonly kyCredentials: typeof ky;

  private token: IToken | null = null;

  constructor() {
    log.debug(
      `Creating API instance, URL: ${DefaultConfig.prefixUrl as string}`,
    );

    const kyOptions: Options = {
      credentials: 'omit',
      hooks: {
        beforeRequest: [
          (request) => {
            this.token?.accessToken &&
              request.headers.set('Authorization', this.getAuthHeader() ?? '');
          },
        ],
        beforeRetry: [
          async ({ request }) => {
            try {
              const { token } = await this.ky
                .post(ApiRoutes.Refresh)
                .json<ILoginPayload>();
              this.setToken(token);
              request.headers.set('Authorization', this.getAuthHeader() ?? '');
            } catch (err) {
              this.clearToken();
              throw err;
            }
          },
        ],
      },
      ...DefaultConfig,
    };

    this.ky = ky.create(kyOptions);
    this.kyCredentials = this.ky.extend({ credentials: 'same-origin' });
  }

  getApiRoute(route: ApiRoutes, ...args: string[]) {
    return urlJoin(API_URL, 'api', route, ...args);
  }

  setToken(token: IToken) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  getAuthHeader() {
    return this.token?.accessToken
      ? `Bearer ${this.token.accessToken}`
      : undefined;
  }

  clearToken() {
    this.token = null;
  }

  private downloadBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }

  download(location: string, filename: string) {
    return this.ky
      .get(location)
      .blob()
      .then((blob) => this.downloadBlob(blob, filename));
  }

  upload(url: string, file: FileWithPath) {
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      body: formData,
    };

    return this.ky.post(url, options);
  }

  enableRefresh() {
    log.debug('enabling refresh');
  }

  disableRefresh() {
    log.debug('disabling refresh');
  }

  async getErrorMessage(error: unknown) {
    if (error instanceof HTTPError) {
      const errorResponse = await error.response.json<{ message?: string }>();
      const msgId = errorResponse.message || '';
      return ErrorMessage[msgId] || msgId || 'Unknown Error';
    }
    return error instanceof Error ? error.toString() : 'Unknown Error';
  }
}

export default new Api();
